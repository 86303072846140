import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "gatsby"
    }}>{`Gatsby`}</h1>
    <p>{`In order to use MDX with `}<a parentName="p" {...{
        "href": "https://gatsbyjs.org"
      }}>{`Gatsby`}</a>{` you can use the `}<a parentName="p" {...{
        "href": "https://github.com/ChristopherBiscardi/gatsby-mdx"
      }}>{`gatsby-mdx`}</a>{` package.`}</p>
    <p>{`First, scaffold a new Gatsby 2.0 or greater site and install the `}<inlineCode parentName="p">{`gatsby-mdx`}</inlineCode>{`
plugin.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`gatsby new gatsby-site https://github.com/gatsbyjs/gatsby-starter-default#v2
cd gatsby-site
yarn add gatsby-mdx @mdx-js/mdx@next @mdx-js/react@next
`}</code></pre>
    <p>{`Then add `}<inlineCode parentName="p">{`gatsby-mdx`}</inlineCode>{` to your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` in the `}<inlineCode parentName="p">{`plugins`}</inlineCode>{` section.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`module.exports = {
  siteMetadata: {
    title: \`My Ambitious Project\`
  },
  plugins: [\`gatsby-mdx\`]
}
`}</code></pre>
    <p>{`Finally, add an `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` file in the `}<inlineCode parentName="p">{`src/pages`}</inlineCode>{` directory.
It “Just Works”.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`# My first MDX Page

some awesome content
`}</code></pre>
    <p>{`For more documentation on programmatically creating pages with Gatsby, see
the `}<a parentName="p" {...{
        "href": "https://github.com/ChristopherBiscardi/gatsby-mdx"
      }}>{`gatsby-mdx docs`}</a>{`.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      